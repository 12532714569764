import React from 'react';

const IntegrationsStonji: React.FC<React.SVGProps<SVGSVGElement>> = ({
  ...props
}) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="6.10828"
        y="11.7998"
        width="9.91318"
        height="9.91318"
        rx="2"
        transform="rotate(11.6554 6.10828 11.7998)"
        fill="#00A868"
      />
      <path
        d="M6.80165 15.4302C7.29776 14.3117 8.67293 13.8969 9.70476 14.5545L14.9698 17.9099L14.2184 21.5524C13.9952 22.6342 12.9374 23.3303 11.8556 23.1071L6.5359 22.0098C5.28649 21.7521 4.59447 20.4062 5.11173 19.2401L6.80165 15.4302Z"
        fill="#1D232A"
      />
      <rect
        x="3.86829"
        y="11.3379"
        width="9.91318"
        height="9.91318"
        rx="2"
        transform="rotate(11.6554 3.86829 11.3379)"
        fill="#00D483"
      />
      <path
        d="M13.115 15.5811L15.2014 16.79L15.1244 17.1635L13.038 15.9545L13.115 15.5811Z"
        fill="#1D232A"
      />
      <path
        d="M12.9598 16.3281L15.0463 17.5371L14.9692 17.9105L12.8828 16.7015L12.9598 16.3281Z"
        fill="#1D232A"
      />
      <rect
        x="8.33472"
        y="0.460938"
        width="9.91318"
        height="9.91318"
        rx="2"
        transform="rotate(11.6554 8.33472 0.460938)"
        fill="#00A868"
      />
      <path
        d="M9.02699 4.09228C9.52311 2.97381 10.8983 2.55902 11.9301 3.21661L17.1951 6.57206L16.4437 10.2145C16.2206 11.2963 15.1627 11.9924 14.0809 11.7692L8.76124 10.6719C7.51183 10.4142 6.81981 9.06835 7.33707 7.9022L9.02699 4.09228Z"
        fill="#1D232A"
      />
      <rect
        x="6.09351"
        y="-0.000976562"
        width="9.91318"
        height="9.91318"
        rx="2"
        transform="rotate(11.6554 6.09351 -0.000976562)"
        fill="#00D483"
      />
      <path
        d="M15.3402 4.24219L17.4266 5.45118L17.3496 5.82459L15.2632 4.6156L15.3402 4.24219Z"
        fill="#1D232A"
      />
      <path
        d="M15.1863 4.98926L17.2727 6.19825L17.1957 6.57167L15.1093 5.36267L15.1863 4.98926Z"
        fill="#1D232A"
      />
      <rect
        x="20.4255"
        y="8.72168"
        width="9.91318"
        height="9.91318"
        rx="2"
        transform="rotate(11.6554 20.4255 8.72168)"
        fill="#00A868"
      />
      <path
        d="M21.1179 12.353C21.614 11.2346 22.9892 10.8198 24.021 11.4774L29.286 14.8328L28.5347 18.4753C28.3115 19.5571 27.2537 20.2531 26.1719 20.03L20.8522 18.9326C19.6028 18.6749 18.9108 17.3291 19.428 16.1629L21.1179 12.353Z"
        fill="#1D232A"
      />
      <rect
        x="18.1846"
        y="8.25977"
        width="9.91318"
        height="9.91318"
        rx="2"
        transform="rotate(11.6554 18.1846 8.25977)"
        fill="#00D483"
      />
      <path
        d="M27.4312 12.5029L29.5176 13.7119L29.4406 14.0853L27.3541 12.8763L27.4312 12.5029Z"
        fill="#1D232A"
      />
      <path
        d="M27.2771 13.25L29.3635 14.459L29.2865 14.8324L27.2001 13.6234L27.2771 13.25Z"
        fill="#1D232A"
      />
      <rect
        x="18.2252"
        y="20.2881"
        width="9.91318"
        height="9.91318"
        rx="2"
        transform="rotate(11.6554 18.2252 20.2881)"
        fill="#00A868"
      />
      <path
        d="M18.9175 23.9194C19.4136 22.801 20.7888 22.3862 21.8206 23.0438L27.0856 26.3992L26.3342 30.0417C26.1111 31.1235 25.0532 31.8195 23.9714 31.5964L18.6517 30.499C17.4023 30.2413 16.7103 28.8955 17.2276 27.7294L18.9175 23.9194Z"
        fill="#1D232A"
      />
      <rect
        x="15.984"
        y="19.8262"
        width="9.91318"
        height="9.91318"
        rx="2"
        transform="rotate(11.6554 15.984 19.8262)"
        fill="#00D483"
      />
      <path
        d="M25.2308 24.0693L27.3173 25.2783L27.2402 25.6517L25.1538 24.4428L25.2308 24.0693Z"
        fill="#1D232A"
      />
      <path
        d="M25.0768 24.8164L27.1632 26.0254L27.0862 26.3988L24.9998 25.1898L25.0768 24.8164Z"
        fill="#1D232A"
      />
    </svg>
  );
};

export default IntegrationsStonji;
